
import { mapGetters, mapMutations, mapActions } from 'vuex'
import filters from "~/mixins/filters";
import cardClick from "~/mixins/cardClick";

export default {
	mixins: [filters, cardClick],
	mounted() {
		this.$refs.slider_small_desc.addEventListener('contextmenu', event => event.preventDefault());
	},
	props: {
		isForm: {
			type: Boolean,
			default: false
		},
		choose: {
			type: Boolean,
			default: false
		},
		hasButtons: {
			type: Boolean,
			default: true
		},
		slide: {
			type: Boolean,
			default: false
		},
		service: Object,
		tag: String
	},
	computed: {
		...mapGetters({
			// benefitsSlider_credit: 'benefits/benefitsSlider_credit',
			settings: 'settings/settings/settings',
		}),
		circleContent() {
			// return ['ставка от', this.settings.credit_percent]
			switch (this.tag) {
				case 'credit':
					return ['от', this.settings.credit_percent]
				case 'installment':
					return ['от', this.settings.installment_percent]
				case 'buyout':
					return ['', 'выгодно']
				case 'tradein':
					return ['выгода до', this.settings.sale_tradein.replace('до', '')]

				// default:
				//     break;
			}
		}
	}

}
